<template>
    <div class="partner-admin-box" id="partner-admin-box">
        <title-nav :title="''"/>
        <div class="title">
          <span>合伙人后台管理</span>
        </div>
        <div class="partner-admin">
          <van-grid :column-num="3" :icon-size="45">
            <van-grid-item v-for="(list) in List" :key="list.id" :icon="list.icon" :text="list.title" @click="handleClick(list.id)"/>
          </van-grid>
          <div class="row">
            <div class="button" @click="copyToClipboard(myWebUrl)">复制我的网址</div>
          </div>
        </div>
    </div>
</template>

<script>
import { queryDomainByUid  } from '@/api/api'
import { wxSdkShare, isWeiXinUA } from '@/utils/index';
import {Toast} from 'vant'
import TitleNav from '../../components/nav/TitleNav.vue'
const seo = require('../../../config/index')

export default {
  name: 'PartnerAdmin',
  components: {
    TitleNav,
  },
  created(){
    this.getData()
  },
  mounted(){
    if(isWeiXinUA()){
      this.wxShareInit()
    }
  },
  data:()=>{
    return {
      isLogin: localStorage.getItem('token') ? true : false,
      myWebUrl: '',
      List:[{
        id:'IncomeList',
        title: '我的收入',
        icon: 'cash-back-record'
      },{
        id:'UserList',
        title: '用户列表',
        icon: 'friends-o'
      },{
        id:'MomentsText',
        title: '朋友圈文案',
        icon: 'font-o'
      },{
        id:'Poster',
        title: '营销海报',
        icon: 'photo-o'
      },{
        id:'QRCode',
        title: '营销二维码',
        icon: 'qr'
      },{
        id:'WeChatPublic',
        title: '公众号菜单',
        icon: 'chat-o'
      }],
    }
  },
  methods:{
    getData(){
      let uidTemp = this.isLogin ? JSON.parse(localStorage.getItem('userInfo'))[0].uid : ''
      let promo_code = JSON.parse(localStorage.getItem('userInfo'))[0].promotion_code
      queryDomainByUid({ uid: uidTemp }).then(res=>{
        this.myWebUrl = 'http://' + res.data.data[0].url + '?promo_code=' + promo_code
      })
    },
    // 复制到剪切板
    copyToClipboard(text) {
      const textarea = document.createElement("textarea"); // 创建一个textarea元素
      textarea.value = text; // 设置textarea的值
      document.body.appendChild(textarea); // 将textarea添加到DOM中
      textarea.select(); // 选中textarea中的文本
      document.execCommand("copy"); // 将选中的文本复制到剪贴板中
      document.body.removeChild(textarea); // 从DOM中移除textarea
      Toast('已复制');
    },
    handleClick(id){
      switch(id){
        case 'QRCode':
          this.$router.push('/qrcode');
          break;
        case 'UserList':
          this.$router.push('/partner-user-list');
          break;
        case 'IncomeList':
          this.$router.push('/partner-income-list');
          break;
        case 'Poster':
          this.$router.push('/partner-poster');
          break;
        case 'MomentsText':
          this.$router.push('/partner-moments-text');
          break;
        case 'WeChatPublic':
          this.copyToClipboard(this.myWebUrl)
          break;
        default:
          break;
      }
    },
    wxShareInit(){
      let params = {}
      params.url = seo.config.wxConfig.partnerAdminPage.link + this.$route.fullPath
      params.context = seo.config.wxConfig.partnerAdminPage.desc
      params.title = seo.config.wxConfig.partnerAdminPage.title
      params.imgUrl = seo.config.wxConfig.partnerAdminPage.imgUrl
      params.appId = seo.config.wxConfig.appId
      wxSdkShare(params)
    }
  },
  activated(){

  }
}
</script>

<style scoped lang="scss">
.partner-admin-box {
    min-height: calc(100vh - 20px);
    padding: 0px 0 20px;
    background: #fff;
    .title {
      color: #ec745b;
      font-size: 28px;
      height: 100px;
      line-height: 100px;
    }
    .partner-admin {
      .row {
        display: flex;
        justify-content: center;
        margin: 20px 0;
        .button {
            width: 80%;
            height: 38px;
            display: flex;
            line-height: 30px;
            border-radius: 30px;
            align-items: center;
            font-size: 16px;
            color: #fff;
            background: #ec745b;
            justify-content: center;
            border: 1px solid #ec745b;
        }
      }
    }
}
</style>